/* roboto-regular - latin */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  src: url("/assets/fonts/roboto-v30-latin/roboto-v30-latin-regular.eot");
  /* IE9 Compat Modes */
  src: local(""),
    url("/assets/fonts/roboto-v30-latin/roboto-v30-latin-regular.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */
      url("/assets/fonts/roboto-v30-latin/roboto-v30-latin-regular.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("/assets/fonts/roboto-v30-latin/roboto-v30-latin-regular.woff")
      format("woff"),
    /* Modern Browsers */
      url("/assets/fonts/roboto-v30-latin/roboto-v30-latin-regular.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("/assets/fonts/roboto-v30-latin/roboto-v30-latin-regular.svg#Roboto")
      format("svg");
  /* Legacy iOS */
}

html {
  --primary-color: #7f4e82;
  --secondary-dark-color: #8bc53f;
  --pin-text-color: #c37;
  --pin-translation-color: #77a;
  --text-translation-color: #63638d;
  --purple-light: #bf82c3;
}
.dark-mode {
  --primary-color: #a880af;
  --secondary-dark-color: #8bc53f;
  --pin-text-color: #e26da0;
  --pin-translation-color: #b1b1df;
  --text-translation-color: #b1b1df;
  --purple-light: #bf82c3;
}

.dev {
  font-size: 10px;
  font-family: Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
    system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", sans-serif;
}

a {
  color: var(--primary-color);
}

.page-main {
  box-sizing: border-box;
  min-height: 100vh;
}

.page-main,
.page-footer-inner {
  max-width: 100%;
  margin: 0 auto;
  padding: 0 5vw;
}

.page-main-inner {
  border: solid 1px transparent;
}

.page-footer-spacer {
  box-sizing: border-box;
}

.page-footer {
  box-sizing: border-box;
  width: 100%;
}

// --- Height of the footer ---

.page-footer-spacer,
.page-footer {
  height: 122px;
}

.page-main {
  margin-bottom: -122px;
}

.landing-page.page-main {
  background: #fff;
  background: linear-gradient(
    -10deg,
    #5d9943 -200%,
    #fff 50%,
    #fff 80%,
    #8bc53f 200%
  );
}
.dark-mode .landing-page.page-main {
  background: #111;
  background: linear-gradient(
    -10deg,
    #5d9943 -200%,
    #111 50%,
    #111 80%,
    #8bc53f 200%
  );
}

.primary-narrow-background-gradient {
  background-color: #7f4e82;
  background: linear-gradient(2deg, #532757, #bf82c3 150%);
}
.primary-narrow-text-gradient {
  display: inline-block;
  text-align: center;
  background-color: #7f4e82;
  color: white;
  font-weight: bold;
  background: -webkit-linear-gradient(86deg, #532757, #bf82c3);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  border-radius: 1px;
}

// --- Footer customization ---

.page-footer-inner {
  box-sizing: border-box;
  padding-top: 2rem;
  text-align: left;
}

.page-footer-inner .smaller {
  font-size: smaller;
}

.page-footer {
  // background: #f7f7f7;
}

.page-main {
  background: #fff;
  /* background-image: url('/images/bed_o90.jpg');
  background-size: cover;
  background-position: center; */
  text-align: center;
}

// Transcript

.transcript-chapter {
  margin-top: 0.25em;
  margin-bottom: 0.25em;
}
.transcript-paragraph {
  margin-top: 0.25em;
  margin-bottom: 0.25em;

  .sentence {
    margin-top: 0.25em;
    margin-bottom: 0.25em;
    // display: grid;
    // grid-template-columns: "1fr auto";
    // gap: 1em;
    // align-items: center;
  }
}
.highlight-area {
  .segment {
    cursor: pointer;
  }

  .segment:hover {
    border-bottom: 5px solid #9cf;
  }

  .word-highlight {
    color: #000;
    background: #bfdf96;
  }

  .remove-word-highlight {
    color: inherit;
    background: none;
  }
}

.dark-mode .highlight-area {
  .segment:hover {
    border-bottom: 5px solid #369;
  }
  .word-highlight {
    color: #fff;
    background: #3b5b2d;
    // background: #476e36;
  }
}

#track-screen {
  // parent is relative, make available of all space
  position: absolute;
  inset: 0;

  // player control row (2nd child) at the bottom
  display: grid;
  grid-template-rows: 1fr auto;

  > .main {
    // Make it relative, so that the <Screen> has absolute positioning (which is needed for the other cases)
    position: relative;
  }
}

#playing-track-bar {
  cursor: pointer;
  height: 64px;
  display: grid;
  grid-template-columns: auto 1fr auto;
  gap: 8px;
  align-items: center;

  > * {
    max-height: 64px;
  }

  .title {
    // Avoid cutting lines horizontally
    max-height: 48px; // 2 lines
    overflow: hidden;
  }

  .control {
    // Make a square that takes up the height of the bar
    width: 64px;
    height: 64px;

    // Make the square slightly darker, so that it is obvious it's a separate element
    background-color: rgba(0, 0, 0, 0.04);
  }
}
.dark-mode #playing-track-bar .control {
  background-color: rgba(255, 255, 255, 0.04);
}

.bottom-panel {
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.25);
  background-color: #e9e3e0;
}
.dark-mode .bottom-panel {
  background-color: #333;
}

main.page {
  margin: 4rem auto;
  max-width: 60em;
  padding: 0 1rem;

  article {
    h1 {
      font-size: 2.5rem;
      font-weight: 400;
      margin: 2rem 0 0 0;
      padding: 0;
    }

    .question-section {
      margin: 2rem 0;
      h2 {
        font-size: 1.2rem;
        font-weight: 400;
        margin: 0;
        padding: 0;
      }
      p {
        margin: 0.5em 0;
      }
    }
  }
}

.text-background {
  background: #eee;
}
.dark-mode .text-background {
  background: #111;
}

.light-mode .control-panel {
  background-color: #e9e3e0;
}
.dark-mode .control-panel {
  background-color: #333;
}

.pin-display {
  .pin-text {
    color: var(--pin-text-color);
  }
  .pin-translation {
    color: var(--pin-translation-color);
  }
}

.dark-mode {
  .block-pin-display {
    background-color: #222;
  }
}

.image-placeholder {
  background: linear-gradient(
    45deg,
    rgba(127, 127, 127, 0.3) 0%,
    transparent 150%
  );
}
.dark-mode .image-placeholder {
  background: linear-gradient(
    225deg,
    rgba(127, 127, 127, 0.3) 0%,
    transparent 150%
  );
}

form.my {
  --space-between-form-blocks: 32px;
  --form-block-separator-border: solid 1px #ccc;

  display: flex;
  flex-direction: column;
  gap: var(--space-between-form-blocks);
  max-width: 30em;

  .block-label {
    font-size: 115%;
    margin-bottom: 8px;
    line-height: 1;
    > span {
      position: relative;
      top: -2px; // compensate for margin caused by line-height
      margin-bottom: 6px;
      line-height: 1.2;
    }
  }

  .field-container {
    &.full-width {
      width: 100%;
    }
  }

  &.centered {
    text-align: center;
    // align-items: center;
    margin-left: auto;
    margin-right: auto;

    border-bottom: var(--form-block-separator-border);
    padding-bottom: var(--space-between-form-blocks);

    .block-label {
      text-wrap: balance;

      width: 100%;
      // background-color: white;
      // padding: 8px;
      // border-radius: 3px;
      border-top: var(--form-block-separator-border);
      padding-top: var(--space-between-form-blocks);
    }

    .field-container {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
}

table.bordered {
  border-collapse: collapse;
  border: solid 1px #dde;
  th,
  td {
    border: solid 1px #dde;
    padding: 4px;
  }
}
